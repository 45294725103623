import React from 'react'
import DashboardLogo from './media/images/DashboardLogo.svg'
import ProfileBtnIcon from './media/images/profileBtnIcon.svg'
import TopLogoutIcon from './media/images/topLogoutIcon.svg'
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
function MainTopBar ({response, setResponse}) {
  const navigate = useNavigate()
  const handleLogout = () => {
    Cookies.remove('token')
    Cookies.remove('DoctorToken')
    Cookies.remove('refresh_token')
    navigate('/doctor/signIn')
  }
  return (
    <div className='flex p-5 shadow-sm items-center'>
      <div>
        {response ?
        <button onClick={()=>setResponse(null)} className="bg-[#026AA2] text-white font-semibold rounded-md flex justify-center items-center py-2 px-3 gap-2">
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 1C6 1 1.00001 4.68242 1 6C0.999992 7.31767 6 11 6 11" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg> Go Back</button>
      :  <img src={DashboardLogo} alt='logo' />
      }
    </div>
      <div className='ml-auto flex'>
        <Link to={'/doctor/personal-information'}>
          <button className='text-[#0065A2] text-base font-normal font-inter flex py-2 px-3 shadow-sm rounded-md'>
            <img src={ProfileBtnIcon} alt='logo' />{' '}
            <span className='ml-2 lg:block md:block hidden'>Personal Info</span>
          </button>
        </Link>
        <div className='ml-3'>
          <button onClick={handleLogout} className='text-[#AD0000] text-base font-normal font-inter flex py-2 px-3 shadow-sm rounded-md'>
            <img src={TopLogoutIcon} alt='logo' />{' '}
            <span className='ml-2 lg:block md:block hidden'>Logout</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

export default MainTopBar
