import Cookies from 'js-cookie'; 

const BASE_URL = process.env.REACT_APP_BASE_URL;

const handleResponse = async (response) => {
  if (response.ok) {
    return response.json();
  } else {
    const errorData = await response.json();
    console.error('Error Details:', errorData);
    throw new Error(errorData.message || 'Something went wrong');
  }
};

const handleResponse2 = async (response) => {
  if (response.ok) {
    return response;
  } else {
    const errorData = await response.json();
    console.error('Error Details:', errorData);
    throw new Error(errorData.message || 'Something went wrong');
  }
};

const getAuthHeaders = () => {
  const storedToken = Cookies.get('token');
  if (storedToken) {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${storedToken}`,
    };
  } else {
    throw new Error('No token found in cookies');
  }
};

export const getData = async (url) => {
  try {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'GET',
      headers,
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Error during GET request:', error);
    throw error;
  }
};
export const getInitialData = async (url) => {
  try {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Error during GET request:', error);
    throw error;
  }
};


export const postInitialData = async (url, data) => {
  try {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      console.error('Error response status:', response.status);
      const errorData = await response.json();
      throw new Error(`Error: ${errorData.message || 'Something went wrong'}`);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
    throw error;
  }
};


export const postData = async (url, data) => {
  try {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Error during POST request:', error);
    throw error;
  }
};




export const putData = async (url, data) => {
  // debugger
  try {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Error during PUT request:', error);
    throw error;
  }
};



export const putBody = async (url, bodyData) => {
  const headers = getAuthHeaders();
  const response = await fetch(`${BASE_URL}${url}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(bodyData),
  });
  // Check if the response has content before parsing as JSON
  if (response.status === 204 || response.headers.get('Content-Length') === '0') {
    // No content
    return null;
  }

  // Return the parsed JSON if content exists
  return await response.json();
};



export const deleteData = async (url) => {
  try {
    // Retrieve the token from cookies
    const token = Cookies.get('token'); // Replace 'token' with your actual cookie name

    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      },
    });

    if (response.ok) {
      return response; // Return the response object directly
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Something went wrong');
    }
  } catch (error) {
    console.error('Error during DELETE request:', error);
    throw error;
  }
};


export const block = async (url) => {
  // debugger
  try {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'PUT',
      headers,
      // body: JSON.stringify(data),
    });
    return await handleResponse2(response);
  } catch (error) {
    console.error('Error during Block request:', error);
    throw error;
  }
};





export const postBody = async (url, data) => {
  try {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || 'Network response was not ok');
    }

    const contentLength = response.headers.get('content-length');
    if (contentLength === '0') {
      return {}; 
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    throw new Error(error.message || 'An unexpected error occurred');
  }
};