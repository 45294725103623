import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DoctorSideImg from '../../../components/media/images/DoctorSideImg.svg'
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg'
import viewIcon from '../../../components/media/images/view.svg'

const UnregisteredDoctorForgetPassword = () => {
  const navigate = useNavigate()

  const handleSignup = e => {
    e.preventDefault()
    navigate('/documentsOTP')
  }

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className='min-h-screen'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='px-14 py-10 mx-auto w-full max-w-xl'>
          <img className='mx-auto mb-5' src={DashboardLogo} alt='logo' />
          <div className='my-3'>
            <h3 className='text-3xl text-[#262626] font-semibold font-inter'>
            Forgot Password
            </h3>
            
          </div>
          <form>
            <div className='mb-4'>
              <input
                type='email'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Email'
              />
            </div>
            {/* <div className='mb-6'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Password *
              </label>
              <input
                type='password'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Password'
              />
            </div> */}
            {/* <div className='mb-6 relative'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Password *</label>
            <input
              type={showPassword ? 'text' : 'password'}
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Password'
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
            >
              <img src={viewIcon} alt="view icon" />
            </button>
          </div> */}
            <Link to='/UnregisteredDoctor/VerifyOtp'>
              <button
                //  type="submit"
                className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:shadow-lg'
              >
               Next
              </button>
            </Link>
          </form>
          {/* <div className='text-center mt-5'>
            <Link to={'/doctor/forgot-password'}>
            <span className='text-[#0065A2] text-lg font-inter font-medium'>
              Forget password?
            </span>
            </Link>
          </div>
          <div className='text-center mt-5'>
            <p className='text-[#262626] text-lg font-medium font-inter'>
              Don't have an account? 
              &nbsp; 
              <Link to={'/doctor/signup'}>
              <span className='text-[#0065A2] text-lg font-inter font-medium'>
                Create One
              </span>
              </Link>
            </p>
          </div> */}
        </div>
   
        <div className='h-[100vh] overflow-hidden rounded-b-[30px]'>
          <img
            src={DoctorSideImg}
            alt='sideImg'
            className='w-full rounded-b-[30px]'
          />
        </div>
      </div>
    </div>
  )
}

export default UnregisteredDoctorForgetPassword
