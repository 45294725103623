import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DoctorSideImg from '../../../components/media/images/DoctorSideImg.svg';
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg';
import viewIcon from '../../../components/media/images/view.svg';
import Select from 'react-select';
import { getData, getInitialData, postData, postInitialData } from '../../../components/Utility/apiServices';
import Swal from 'sweetalert2';

const Signup = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [error, setError] = useState('');
  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getInitialData('/app/lookups');
      const specialities = response?.specialities || [];
      const options = specialities.map(speciality => ({
        value: speciality,
        label: speciality,
      }));
      setSpecialityOptions(options);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const togglePasswordVisibility1 = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  // const specialityOptions = [
  //   { value: 'cardiology', label: 'Cardiology' },
  //   { value: 'neurology', label: 'Neurology' },
  //   { value: 'pediatrics', label: 'Pediatrics' },
  //   { value: 'general-surgery', label: 'General Surgery' },
  // ];

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const response = await postInitialData('/doctor/auth/register', {
        name,
        email,
        contact_number: contactNumber,
        password,
        speciality: selectedSpeciality?.value,
      });
      // console.log('Signup response:', response);
      Swal.fire({
        icon: 'success',
        title: 'User Created',
        text: response.message,
      });
      navigate('/doctor/signIn');
    } catch (error) {
      setError(error.message); 
      Swal.fire({
        icon: 'error',
        title: 'Signup Failed',
        text: error.message,
      });
    }
  };

  return (
    <div className="min-h-screen">
      <div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4'>
        <div className='px-14 py-10 w-full max-w-xl mx-auto'>
          <img className='mx-auto mb-5' src={DashboardLogo} alt="logo" />
          <div className='my-3'>
            <h3 className='text-3xl text-[#262626] font-semibold font-inter'>Create New Account</h3>
            <p className='text-[#262626] text-sm font-light font-inter mt-1'>Sign up now to check patients</p>
          </div>
          <form onSubmit={handleSignup}>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Name *</label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Email *</label>
              <input
                type='email'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Phone *</label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Phone'
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Speciality</label>
              <Select
                value={selectedSpeciality}
                onChange={setSelectedSpeciality}
                options={specialityOptions}
                className='w-full mt-1'
                placeholder='Select Speciality'
              />
            </div>
            <div className='mb-6 relative'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Password *</label>
              <input
                type={showPassword ? 'text' : 'password'}
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility1}
                className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
              >
                <img src={viewIcon} alt="view icon" />
              </button>
            </div>
            <div className='mb-6 relative'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Confirm Password *</label>
              <input
                type={showPassword2 ? 'text' : 'password'}
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility2}
                className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
              >
                <img src={viewIcon} alt="view icon" />
              </button>
            </div>
            {error && <p className='text-red-500 text-sm'>{error}</p>}
            <button
              type='submit'
              className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:bg-[#462ca3]'
            >
              Create Account
            </button>
          </form>
          <div className='text-center mt-5'>
            <p className='text-[#262626] text-lg font-medium font-inter'>
              Already have an account? 
              <Link to={'/doctor/signIn'}>
                <span className='text-[#0065A2] text-lg font-inter font-medium'> Sign in Now</span>
              </Link>
            </p>
          </div>
        </div>
        <div className="h-[auto] overflow-hidden rounded-b-[30px] lg:block md:hidden hidden">
          <img
            src={DoctorSideImg}
            alt="sideImg"
            className="w-full rounded-b-[30px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
