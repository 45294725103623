import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import OtpImg from '../../../components/media/images/OtpImg.svg'
import MainTopBar from '../../../components/mainTopBar'
import ViewPDFData from '../../../components/RegisteredPDFDataView'
import { postBody } from '../../../components/Utility/apiServices'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie';  

const OTPVerification = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('token');
    const DoctorToken = Cookies.get('DoctorToken');
    if (!DoctorToken && !token) {
      navigate('/doctor/signIn'); 
    }
  }, [navigate]);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const [response, setResponse] = useState(null)
  const [documentId, setDocumentId] = useState('') // Store documentId in state

  // Fetch report function to refresh the report data
  const fetchReportData = async (documentId, otp) => {
    const bodyData = {
      document_id: documentId,
      otp: otp
    }

    try {
      const response = await postBody('/doctor/registered/report', bodyData)
      setResponse(response)
      setDocumentId(documentId)
    } catch (error) {
      const parsedError = JSON.parse(error.message)
      const errorMessage = parsedError?.message || 'Please Try Again'

      console.error('Error fetching report:', errorMessage)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          errorMessage ||
          'Something went wrong while fetching the report. Please try again.',
        confirmButtonText: 'OK'
      })
    }
  }

  const onSubmit = async data => {
    await fetchReportData(data?.documentId, data?.otp)
  }

  return (
    <>
      <MainTopBar response={response} setResponse={setResponse}/>
      {!response && (
        <div className='mt-10 bg-cover bg-no-repeat bg-center flex items-center justify-center'>
          <div className='p-5 bg-[#ffffffd1] rounded-3xl w-full max-w-xl'>
            <img className='mx-auto' src={OtpImg} alt='logo' />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Document ID
                </label>
                <input
                  type='text'
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter Patient Document ID'
                  {...register('documentId', {
                    required: 'Document ID is required'
                  })}
                />
                {errors.documentId && (
                  <p className='text-red-600'>{errors.documentId.message}</p>
                )}
              </div>
              <div className='mb-6'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  OTP
                </label>
                <input
                  type='text'
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='e.g 123456'
                  {...register('otp', { required: 'OTP is required' })}
                />
                {errors.otp && (
                  <p className='text-red-600'>{errors.otp.message}</p>
                )}
              </div>
              <button
                type='submit'
                className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:shadow-lg'
              >
                Check Report
              </button>
            </form>
          </div>
        </div>
      )}
      {response && (
        <ViewPDFData
          response={response}
          documentId={documentId}
          fetchReportData={fetchReportData}
        />
      )}
    </>
  )
}

export default OTPVerification
