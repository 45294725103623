import React from 'react';
import UDMainTopBar from '../../components/UDmainTopBar';

const PrivacyPolicy = () => {
    return (
        <>
        <UDMainTopBar response={null} setResponse={()=>{}} />
       
        <div className="max-w-4xl mx-auto p-8 ">
            <h1 className="text-3xl font-bold mb-4">SMFmed Privacy Policy</h1>
            <p className="mb-4 text-gray-600">Last Updated: September 2024</p>
            <p className="mb-6">
                At SMFmed, we value the privacy and security of your personal and health information.
                We comply with HIPAA regulations and ensure the confidentiality and protection of
                sensitive data. This Privacy Policy explains what data we collect, how we use it, and
                the steps we take to protect it.
            </p>
            <p className="mb-6">
                By using the SMFmed app, you agree to this Privacy Policy and the practices described herein.
            </p>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">1. Data We Collect</h2>

            <h3 className="text-xl font-semibold mb-2">a) Personal Information</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Address</li>
                <li>Date of birth</li>
                <li>Gender</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">b) Sensitive Health Information</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Medical records (test results, prescriptions, diagnoses)</li>
                <li>Health insurance information (policy details, coverage)</li>
                <li>Provider and treatment interactions</li>
                <li>Claims, payment data, and co-pay information</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">c) Device Information</h3>
            <ul className="list-disc pl-6 mb-6">
                <li>IP address</li>
                <li>Device type</li>
                <li>Operating system</li>
                <li>App usage data</li>
            </ul>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">2. Use of Data</h2>
            <h3 className="text-xl font-semibold mb-2">a) Healthcare Services</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Store and allow access to your medical records and health data.</li>
                <li>
                    Facilitate payments and claims processing based on your insurance policy or employer-provided health benefits.
                </li>
                <li>
                    Enable sharing of medical records with service providers such as doctors, hospitals, labs, and pharmacies via a secure OTP system, as authorized by you.
                </li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">b) Operational Efficiency</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Analyze app usage to improve user experience and app functionality.</li>
                <li>Develop new features and tools to enhance medical data management and payment processes.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">c) Communication</h3>
            <ul className="list-disc pl-6 mb-6">
                <li>
                    Send reminders, notifications, and updates regarding your healthcare records, claims status, and service appointments.
                </li>
                <li>Respond to inquiries and provide technical support.</li>
            </ul>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">3. Sharing of Data</h2>

            <h3 className="text-xl font-semibold mb-2">a) With Your Authorization</h3>
            <p className="mb-4">
                We will only share your medical and health-related data with third-party healthcare providers or organizations if you give explicit consent via OTP, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>Medical professionals (doctors, hospitals)</li>
                <li>Laboratories, pharmacies</li>
                <li>Insurance companies or employers for claims processing</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">b) As Permitted or Required by Law</h3>
            <p className="mb-4">
                In certain circumstances, we may disclose your data to comply with legal requirements such as court orders, subpoenas, or regulations. This is done only when legally justified and necessary.
            </p>

            <h3 className="text-xl font-semibold mb-2">c) Business Associates</h3>
            <p className="mb-6">
                We may share data with trusted third-party partners who perform services on our behalf (e.g., cloud storage, payment processing). These parties are bound by HIPAA Business Associate Agreements (BAAs) to safeguard your information.
            </p>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
            <p className="mb-4">
                We take data security seriously and employ a range of measures to protect your personal and health information:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>
                    <strong>HIPAA Compliance:</strong> SMFmed ensures compliance with HIPAA’s Privacy Rule and Security Rule by implementing appropriate administrative, technical, and physical safeguards.
                </li>
                <li>
                    <strong>Encryption:</strong> All sensitive data is encrypted both at rest and in transit using industry-standard encryption technologies.
                </li>
                <li>
                    <strong>Access Controls:</strong> Access to your data is strictly limited to authorized personnel and healthcare providers using a multi-factor authentication process.
                </li>
                <li>
                    <strong>Audit Trails:</strong> We maintain logs of access to your data for accountability and auditing purposes.
                </li>
            </ul>


            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
            <p className="mb-6">
                We retain your data only for as long as necessary to provide services and comply with legal obligations. Upon account
                closure, we will delete your data within a reasonable period, unless retention is required by law or for healthcare
                purposes.
            </p>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">6. Your Rights Under HIPAA</h2>
            <p className="mb-4">Under HIPAA, you have specific rights regarding your health information, including:</p>
            <ul className="list-disc pl-6 mb-6">
                <li><strong>Right to Access:</strong> You can request and access copies of your personal and health information at any time.</li>
                <li><strong>Right to Amend:</strong> You may request amendments to your health records if you believe the information is incorrect.</li>
                <li><strong>Right to Restrict Sharing:</strong> You have the right to request restrictions on how your data is shared with providers or insurers.</li>
                <li><strong>Right to an Accounting of Disclosures:</strong> You may request a report detailing when and why your health information was shared with others.</li>
            </ul>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">7. Children’s Privacy</h2>
            <p className="mb-6">
                SMFmed’s services are not intended for individuals under the age of 18. We do not knowingly collect or store personal
                information from children without parental consent.
            </p>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">8. Changes to This Policy</h2>
            <p className="mb-6">
                We may update this Privacy Policy periodically to reflect changes in our practices or regulatory requirements. If we
                make significant changes, we will notify you through the app or via email.
            </p>

            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
            <p className="mb-6">
                If you have any questions or concerns about our Privacy Policy or your HIPAA rights, please contact us at:
            </p>
            <ul className="list-disc pl-6 mb-6">
                <li>Email: <a href="mailto:support@smfmed.com" className="text-blue-600">support@smfmed.com</a></li>
                <li>Phone: +201000888101</li>
            </ul>
            <hr className="my-6" />

            <h2 className="text-2xl font-semibold mb-4">10. Your Acknowledgment</h2>
            <p className="mb-6">
                By using SMFmed, you acknowledge that you have read and understood this Privacy Policy and agree to its terms. Please ensure to review it periodically for updates.
            </p>

            <hr className="my-6" />

            <p className="mb-6">
                This Privacy Policy ensures that SMFmed not only complies with HIPAA but also gives your users clear insight into how their sensitive information is managed. This should cover the necessary aspects of data handling, privacy, and user rights in the healthcare space.
            </p>
        </div>
        </>
    );
};

export default PrivacyPolicy;
