import React, { useState, useEffect } from 'react';
import Table from '../../../components/table';
import searchIcon from '../../../components/media/images/searchIcon.svg';
import DeleteIcon from '../../../components/media/images/DeleteIcon.svg';
import userBlockIcon from '../../../components/media/images/userBlockIcon.svg';
import Modal from '../../../components/modal';
import { getData, deleteData, block } from '../../../components/Utility/apiServices';
import { useForm } from 'react-hook-form';

const UsersList = () => {
  const { register, handleSubmit, reset } = useForm(); 
  const [filterText, setFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalQuestion, setModalQuestion] = useState('');
  const [modalIcon, setModalIcon] = useState(null);
  const [isBlock, setIsBlock] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getData('/admin/patients');
        setData(response);
      } catch (error) {
        console.error('Error fetching patients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = data?.filter(item =>
    item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: 'Name',
      selector: row => (
        <div className='flex items-center'>
          {row.avatar ? (
            <img
              src={row.avatar}
              alt={row.name}
              className='w-8 h-8 rounded-full mr-2'
            />
          ) : (
            <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center text-white mr-2'>
              {row.name[0].toUpperCase()}
            </div>
          )}
          <span>{row.profileName}</span>
        </div>
      ),
      sortable: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true
    },
    {
      name: 'Phone',
      selector: row => row.contact_number,
      sortable: true
    },
    {
      name: 'Registration Date',
      selector: row => new Date(row.created_at).toLocaleDateString(),
      sortable: true
    },
    {
      name: 'Status',
      selector: row => <div>{row?.isActive ? "Active" : "Inactive"}</div>,
      sortable: true
    },
    {
      name: 'Action',
      cell: row => (
        <div className='flex'>
          <button
            onClick={() =>
              openModal(`Are you sure you want to delete this user?`, DeleteIcon, row.id)
            }
            className='mx-1'
          >
            <img src={DeleteIcon} alt='deleteButton' />
          </button>
          <button
            onClick={() =>
              openModal(`Are you sure you want to ${row.isActive ? "block" : "unblock"} this user?`, userBlockIcon, row.id, true)
            }
            className='mx-1'
          >
            <img src={userBlockIcon} alt='BlockButton' />
          </button>
        </div>
      )
    }
  ];

  
  const handleDelete = async () => {
    try {
      const response = await deleteData(`/admin/patients/${userIdToDelete}`);
  
      if (response.status === 200) {
        await refetchData();
        setIsModalOpen(false);
      } else {
        alert('Failed to delete user. Please try again later.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. Please try again later.');
    }
  };

  const handleBlock = async () => {
    try {
      const response = await block(`/admin/patients/block/${userIdToDelete}`);
      if (response.status === 200) {
        await refetchData();
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      alert(error?.message);
    }
  }; 

  const refetchData = async () => {
    try {
      setLoading(true);
      const response = await getData('/admin/patients');
      setData(response);
    } catch (error) {
      console.error('Error refetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const openModal = (question, icon, userId = null, block = false) => {
    setModalQuestion(question);
    setModalIcon(icon);
    setUserIdToDelete(userId);
    setIsBlock(block);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsBlock(false);
    setUserIdToDelete(null);
  };

  return (
    <div>
      <div className='flex'>
        <h2 className='text-3xl text-[#262626] font-inter font-semibold mb-4'>
          Patients
        </h2>
        <p className='text-[#262626] ml-auto mt-2 text-base font-normal font-inter'>
          Total Patients <span className='text-[#0065A2]'>{data?.length}</span>
        </p>
      </div>
      <div
        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
        className='border-[1px] mt-5 border-[#E8E8E8] rounded-xl bg-white pt-4 px-3 '
      >
        <div className='relative '>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <img src={searchIcon} alt={'searchIcon'} />
          </span>
          <input
            type='text'
            placeholder='Search by Email'
            className='pl-10 p-2 border border-gray-300 rounded'
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Table columns={columns} data={filteredData} />
        )}
      </div>
      {isModalOpen && (
        <Modal
          question={modalQuestion}
          icon={modalIcon}
          onClose={closeModal}
          onConfirm={handleSubmit(isBlock ? handleBlock : handleDelete)}
        />
      )}
    </div>
  );
};

export default UsersList;
