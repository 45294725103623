import Cookies from 'js-cookie';

export const refreshToken = async () => {
  const refresh_token = Cookies.get('refresh_token');
  if (!refresh_token) throw new Error('No refresh token found');

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/auth/refreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh_token }),
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }
  const data = await response.json();
  const { token, refresh_token: newRefreshToken } = data;

  // Update cookies with new tokens
  Cookies.set('token', data?.accessToken);
  Cookies.set('refresh_token', data?.refreshToken);

  return token;
};
