import React, { useEffect, useState } from 'react';
import Table from '../../../components/table';
import searchIcon from '../../../components/media/images/searchIcon.svg';
import { getData, putBody, putData } from '../../../components/Utility/apiServices';
import Swal from 'sweetalert2';  // Import SweetAlert2

const PendingRequests = () => {
  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getData('/doctor/pending');
      setData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: 'Name',
      selector: row => (
        <div className='flex items-center'>
          {row.image ? (
            <img
              src={row.image}
              alt={row.name}
              className='w-[32px] h-[32px] rounded-full mr-2'
            />
          ) : (
            <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center text-white mr-2'>
               {row.name[0].toUpperCase()}
            </div>
          )}
         <span>
          {row.name.length > 19
            ? row.name.slice(0, 19) + '...'
            : row.name}
        </span>
        </div>
      ),
      sortable: true,
      width: "200px"
    },
    {
      name: 'Speciality',
      selector: row => row.speciality,
      sortable: true,
      width: '200px'
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true
    },
    {
      name: 'Registration Date',
      selector: row => new Date(row.register_date).toLocaleDateString(),
      sortable: true
    },
    {
      name: 'Action',
      cell: row => (
        <div className='flex'>
          <button
            className='bg-[#fbe5e6] mx-1 rounded-lg border-[1px] border-[#DA0004] px-2 py-1 text-[#DA0004] text-base font-inter font-normal'
            onClick={() => handleDecision(row.id, false)}
          >
            Reject
          </button>
          <button
            className='bg-[#0065A2] mx-1 rounded-lg border-[1px] border-[#0065A2] px-2 py-1 text-white text-base font-inter font-normal'
            onClick={() => handleDecision(row.id, true)}
          >
            Approve
          </button>
        </div>
      ),
      width: '200px'
    }
  ];

 


  const handleDecision = async  (id, decision)  => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${decision ? 'approve' : 'reject'} this request?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    });


    if (result.isConfirmed) {
    try {
      const response = await putBody(`/doctor/pending/${id}`, { decision });
      
      if (response && Object.keys(response).length > 0) {
        Swal.fire(
          'Success!',
          `The request has been ${decision ? 'approved' : 'rejected'}.`,
          'success'
        );
        fetchData(); 
      } else {
        console.log("No response body.");
        Swal.fire(
          'Success!',
          `The request has been ${decision ? 'approved' : 'rejected'}.`,
          'success'
        );
        fetchData(); 
      }
    } catch (error) {
      console.error('Error changing password:', error.message);
      Swal.fire(
        'Error!',
        'There was a problem updating the request.',
        'error'
      );
    }finally {
      setLoading(false);
    }
  }
  };





  return (
    <div>
      <div className='flex'>
        <h2 className='text-3xl text-[#262626] font-inter font-semibold mb-4'>
          Pending Doctors Request
        </h2>
        <p className='text-[#262626] ml-auto mt-2 text-base font-normal font-inter'>
          Total Requests <span className='text-[#0065A2]'>3456</span>
        </p>
      </div>
      <div
        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
        className='border-[1px] mt-5 border-[#E8E8E8] rounded-xl bg-white pt-4 px-3 '
      >
        <div className='relative '>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <img src={searchIcon} alt={'searchIcon'} />
          </span>
          <input
            type='text'
            placeholder='Search by Name'
            className='pl-10 p-2 border border-gray-300 rounded'
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
        </div>
        <Table columns={columns} data={filteredData} />
      </div>
    </div>
  );
}

export default PendingRequests;
