import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/admin/login/Login';
import Signup from './pages/doctor/signup/signup';
import DocumentOTP from './pages/doctor/documentOTP/documentOTP';
import PDFView from './pages/doctor/pdfView/pdfView';
import PDFData from './pages/doctor/pdfData/pdfData';
import ForgotPassword from './pages/doctor/forgetPassword/forgetPassword';
import PersonalInformation from './pages/doctor/personalInformation/personalInformation';
import './App.css';
import Admin from './layout/adminLayout';
import SignIn from './pages/doctor/signIn/signIn';
import VerifyOtp from './pages/doctor/verifyOTP/verifyOTP';
import CreateDoctorsPassword from './pages/doctor/CreateDoctorPassword/createDoctorPassword';
import { refreshToken } from './components/helper/refreshToken';
import UnregisteredOTPVerification from './pages/UnregisteredDoctor/documentOTP/documentOTP';
import UnregisteredVerifyOtp from './pages/UnregisteredDoctor/verifyOTP/verifyOTP';
import CreateUnregisteredDoctorsPassword from './pages/UnregisteredDoctor/CreateDoctorPassword/createDoctorPassword';
import UnregisteredPDFData from './pages/UnregisteredDoctor/pdfData/pdfData';
import UnregisteredDoctorForgetPassword from './pages/UnregisteredDoctor/forgetPassword/forgetPassword';
import UnregisteredPersonalInformation from './pages/UnregisteredDoctor/personalInformation/personalInformation';
import PrivacyPolicy from './pages/privacy_policy/privacyPolicy';

import Cookies from 'js-cookie';

const App = () => {
  useEffect(() => {
    const initAuth = async () => {
      try {
        await refreshToken();
      } catch (error) {
        console.error('Token refresh failed:', error);
      }
    };

    initAuth();
  }, []);

  // Function to check if AdminToken exists
  const isAdminLoggedIn = () => {
    return !!Cookies.get('AdminToken');
  };

  return (
    <Router>
      <Routes>

        {/* Registered Doctors Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        
        {/* Conditionally render doctor routes if AdminToken does not exist */}
        {!isAdminLoggedIn() ? (
          <>
            <Route path="/doctor/signIn" element={<SignIn />} />
            <Route path="/doctor/signup" element={<Signup />} />
            <Route path="/doctor/documentsOTP" element={<DocumentOTP />} />
            <Route path="/doctor/VerifyOtp" element={<VerifyOtp />} />
            <Route path="/doctor/createPassword" element={<CreateDoctorsPassword />} />
            <Route path="/pdf-view" element={<PDFView />} />
            <Route path="/doctor/pdf-data" element={<PDFData />} />
            <Route path="/doctor/forgot-password" element={<ForgotPassword />} />
            <Route path="/doctor/personal-information" element={<PersonalInformation />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}

        {/* Unregistered Doctors Routes */}
        <Route path="/UnregisteredDoctor/documentsOTP" element={<UnregisteredOTPVerification />} />
        <Route path="/UnregisteredDoctor/VerifyOtp" element={<UnregisteredVerifyOtp />} />
        <Route path="/UnregisteredDoctor/createPassword" element={<CreateUnregisteredDoctorsPassword />} />
        <Route path="/pdf-view" element={<PDFView />} />
        <Route path="/UnregisteredDoctor/pdf-data" element={<UnregisteredPDFData />} />
        <Route path="/UnregisteredDoctor/forgot-password" element={<UnregisteredDoctorForgetPassword />} />
        <Route path="/UnregisteredDoctor/personal-information" element={<UnregisteredPersonalInformation />} />

        {/* Admin Panel Routes */}
        <Route path="/admin/*" element={<Admin />} />
      </Routes>
    </Router>
  );
};

export default App;
