import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DoctorSideImg from '../../../components/media/images/DoctorSideImg.svg';
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg';
import viewIcon from '../../../components/media/images/view.svg';
import Cookie from 'js-cookie';  
import { postInitialData } from '../../../components/Utility/apiServices';
import Cookies from 'js-cookie';
const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await postInitialData('/doctor/auth/login', { email, password });
      Cookie.set('token', response.token, { expires: 20 });
      Cookie.set('DoctorToken', response.token, { expires: 20 });
      Cookie.set('refresh_token', response.refresh_token, { expires: 20 });  
      Cookies.remove('AdminToken')
      navigate('/doctor/documentsOTP');
    } catch (error) {
      setError(error.message);  
    }
  };


  useEffect(() => {
    const token = Cookies.get("token")
    const DoctorToken = Cookies.get('DoctorToken');
    if (token && DoctorToken) {
      navigate('/doctor/documentsOTP'); 
    }
  }, [navigate]);

  return (
    <div className='min-h-screen'>
      <div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4'>
        <div className='px-14 py-10 mx-auto w-full max-w-xl'>
          <img className='mx-auto mb-5' src={DashboardLogo} alt='logo' />
          <div className='my-3'>
            <h3 className='text-3xl text-[#262626] font-semibold font-inter'>
              Welcome Back!
            </h3>
            <p className='text-[#262626] text-sm font-light font-inter mt-1'>
              Sign in to your account
            </p>
          </div>
          <form onSubmit={handleSignIn}>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Email *
              </label>
              <input
                type='email'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='mb-6 relative'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>Password *</label>
              <input
                type={showPassword ? 'text' : 'password'}
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
              >
                <img src={viewIcon} alt="view icon" />
              </button>
            </div>
            {error && <p className='text-red-500 text-sm'>{error}</p>}
            <button
              type='submit'
              className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:bg-[#462ca3]'
            >
              Sign In
            </button>
          </form>
          <div className='text-center mt-5'>
            <Link to={'/doctor/forgot-password'}>
              <span className='text-[#0065A2] text-lg font-inter font-medium'>
                Forget password?
              </span>
            </Link>
          </div>
          <div className='text-center mt-5'>
            <p className='text-[#262626] text-lg font-medium font-inter'>
              Don't have an account? 
              &nbsp; 
              <Link to={'/doctor/signup'}>
                <span className='text-[#0065A2] text-lg font-inter font-medium'>
                  Create One
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className='h-[100vh] overflow-hidden rounded-b-[30px] lg:block md:hidden hidden'>
          <img
            src={DoctorSideImg}
            alt='sideImg'
            className='w-full rounded-b-[30px]'
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
