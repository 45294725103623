import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form'; 
import OtpImg from '../../../components/media/images/OtpImg.svg';
import UDMainTopBar from '../../../components/UDmainTopBar';
import { postData } from '../../../components/Utility/apiServices';
import Cookies from 'js-cookie'; 
import ViewUDPDFData from '../../../components/PDFDataView';
import Swal from 'sweetalert2';

const UnregisteredOTPVerification = () => {
  const [response, setResponse] = useState(null);  // State to hold API response
  const { register, handleSubmit, formState: { errors } , reset} = useForm(); 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const documentId = queryParams.get('document_id');
  const token = queryParams.get('token');

  useEffect(() => {
    if (token) {
      Cookies.set('token', token); 
    }
  }, [token]);

  const fetchReportData = async (otp) => {
    try {
      const response = await postData('/doctor/unregistered/report', { document_id: documentId, otp: otp });
      setResponse(response);  
    } catch (error) {
      console.error('Error fetching report:', error.message);
    }
  };

  // useEffect(() => {
  //   fetchReportData();
  // }, []);

  const onSubmit = async (data) => {
    const bodyData = {
      document_id: documentId,
      otp: data.otp,
    };

    try {
      const response = await postData('/doctor/unregistered/report', bodyData);
      setResponse(response); 
      reset();
    } catch (error) {
      console.error('Error fetching report:', error);
      // const parsedError = JSON.parse(error.message)
      // const errorMessage = parsedError?.message || 'Please Try Again'

      // console.error('Error fetching report:', errorMessage)
      // setDataError(errorMessage)

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
        error ||
          'Something went wrong while fetching the report. Please try again.',
        confirmButtonText: 'OK'
      })
    }
  };

  return (
    <>
      <UDMainTopBar response={response} setResponse={setResponse} />
      {!response && (
        <div className="mt-10 bg-cover bg-no-repeat bg-center flex items-center justify-center">
          <div className="p-5 bg-[#ffffffd1] rounded-3xl w-full max-w-xl">
            <img className="mx-auto" src={OtpImg} alt="logo" />
            <form onSubmit={handleSubmit(onSubmit)}> 
              <div className="mb-6">
                <label className="text-[#262626] text-sm font-semibold font-inter">OTP</label>
                <input
                  type="text"
                  className="w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                  placeholder="e.g 123456"
                  {...register('otp', { required: 'OTP is required' })}
                />
                {errors.otp && <p className="text-red-600">{errors.otp.message}</p>}
              </div>
              <button
                type="submit"
                className="w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:shadow-lg"
              >
                Check Report
              </button>
            </form>
          </div>
        </div>
      )}
      {response && <ViewUDPDFData response={response} refreshData={fetchReportData} />}  
    </>
  );
};

export default UnregisteredOTPVerification;
