import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import avatar from '../../../components/media/images/Avatar.svg'
import MainTopBar from '../../../components/mainTopBar'
import { getData, putBody, putData, postData, getInitialData } from '../../../components/Utility/apiServices'
import Swal from 'sweetalert2'
import viewIcon from '../../../components/media/images/view.svg'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie'; 

const PersonalInformation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const DoctorToken = Cookies.get('DoctorToken');
    if (!DoctorToken) {
      navigate('/doctor/signIn'); 
    }
  }, [navigate]);
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageFile, setImageFile] = useState(null) 
  const [selectedSpeciality, setSelectedSpeciality] = useState(null)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { register, handleSubmit, setValue } = useForm()


  const [specialityOptions, setSpecialityOptions] = useState([]);
  // const fetchOptions = async () => {
  //   try {
  //     const response = await getInitialData('/app/lookups');
  //     const specialities = response?.specialities || [];
  //     const options = specialities.map(speciality => ({
  //       value: speciality,
  //       label: speciality,
  //     }));
  //     setSpecialityOptions(options);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchOptions();
  // }, []);

  const handleImageChange = e => {
    const file = e.target.files[0]
    if (file) {
      setImageFile(file) 
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(reader.result) 
      }
      reader.readAsDataURL(file)
    }
  }

  // const specialityOptions = [
  //   { value: 'Cardiology', label: 'Cardiology' },
  //   { value: 'Neurology', label: 'Neurology' },
  //   { value: 'Pediatrics', label: 'Pediatrics' },
  //   { value: 'Radiology', label: 'Radiology' }
  // ]

  const fetchData = async () => {
    try {

      const resp = await getInitialData('/app/lookups');
      const specialities = resp?.specialities || [];
      const options = specialities.map(speciality => ({
        value: speciality,
        label: speciality,
      }));
      setSpecialityOptions(options);

      const response = await getData('/doctor/registered/profile/editProfile')
      setData(response)

      setValue('name', response.name || '')
      setEmail(response.email || '')
      setPhone(response.contact_number || '')
      setSelectedSpeciality(
        options.find(
          option => option.value === response.speciality
        ) || null
      )
      setSelectedImage(response.avatar || null)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const uploadImage = async () => {
    if (!imageFile) return null 

    try {
      const response = await postData('/doctor/registered/profile/getPresignedUrlForProfileImage', {
        fileName: imageFile.name,
        contentType: imageFile.type
      })

      const { url, profileImage } = response 
        // debugger
      await axios.put(url, imageFile,{
        headers: {
          'Content-Type': imageFile.type
        },
      })

      return profileImage 
    } catch (error) {
      debugger
      console.error('Error uploading image:', error)
      return null
    }
  }

  const onSubmit = async (formData) => {




    const imageUrl = await uploadImage() 
    


    const bodyData = {
      name: formData.name,
      speciality: selectedSpeciality ? selectedSpeciality.value : null,
      // avatar: imageUrl 
    }

    try {
      await putData('/doctor/registered/profile/editProfile', bodyData)
      Swal.fire({
        icon: 'success',
        title: 'Profile Updated',
        text: 'Your profile has been successfully updated.',
        confirmButtonText: 'OK'
      })
    } catch (error) {
      console.error('Error updating profile:', error.message)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while updating your profile.',
        confirmButtonText: 'OK'
      })
    }
  }

  const handlePasswordSubmit = async (formData) => {
    const passwordData = {
      old_password: formData.currentPassword,
      new_password: formData.newPassword
    }

    try {
      const response = await putBody('/doctor/registered/profile/changePassword', passwordData)

      Swal.fire({
        icon: 'success',
        title: 'Password Changed',
        text: 'Your password has been successfully updated.',
        confirmButtonText: 'OK'
      })
    } catch (error) {
      console.error('Error changing password:', error.message)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while changing your password.',
        confirmButtonText: 'OK'
      })
    }
  }

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <>
      <MainTopBar />
      <form onSubmit={handleSubmit(onSubmit)}> {/* use handleSubmit for form submission */}
        <div className='mt-5 bg-cover bg-no-repeat bg-center flex items-center justify-center'>
          <div className='lg:px-52 md:px-8 px-8 py-1 bg-[#ffffffd1] rounded-3xl w-full max-w-screen-2xl'>
            <h3 className='text-[#0065A2] text-[40px] font-semibold font-inter'>
              Personal Information
            </h3>
            <hr className='py-2'></hr>
            <img
              className='mx-auto rounded-full w-32 h-32 object-cover'
              src={selectedImage || avatar}
              alt='avatar'
            />
            <div className='mx-auto text-center mt-2'>
              <label
                htmlFor='imageUpload'
                className='text-[#0065A2] text-lg font-medium font-montserrat cursor-pointer'
              >
                Upload Image
              </label>
              <input
                id='imageUpload'
                type='file'
                accept='image/*'
                className='hidden'
                onChange={handleImageChange}
              />
            </div>
            <div className='grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3'>
              <div className='mb-4'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Name
                </label>
                <input
                  type='text'
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter Name'
                  {...register('name')} // Use register to link input to react-hook-form
                />
              </div>
              <div className='mb-4'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Email
                </label>
                <input
                  disabled
                  type='text'
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter Email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className='mb-4'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Phone
                </label>
                <input
                  type='text'
                  disabled
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter Phone'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
              <div className='mb-4'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Speciality
                </label>
                <Select
                  value={selectedSpeciality}
                  onChange={setSelectedSpeciality}
                  options={specialityOptions}
                  className='w-full mt-1'
                  placeholder='Select Speciality'
                />
              </div>
            </div>
            <button
              type='submit'
              className='bg-[#0065A2] px-5 mt-3 float-end py-3 rounded-lg text-base font-semibold font-inter text-white'
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>

      {/* Password change section */}
      <form onSubmit={handleSubmit(handlePasswordSubmit)}>
        <div className='mt-10 bg-cover bg-no-repeat bg-center flex items-center justify-center'>
          <div className='lg:px-52 md:px-8 px-8 py-1 bg-[#ffffffd1] rounded-3xl w-full max-w-screen-2xl'>
            <h3 className='text-[#0065A2] text-[40px] font-semibold font-inter'>
              Password Change
            </h3>
            <hr className='py-2'></hr>
            <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3'>
              <div className='mb-4 relative'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Current Password
                </label>
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter Current Password'
                  {...register('currentPassword')}
                />
                <button
                  type="button"
                  onClick={toggleCurrentPasswordVisibility}
                  className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
                >
                  <img src={viewIcon} alt="view icon" />
                </button>
              </div>
              <div className='mb-4 relative'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  New Password
                </label>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Enter New Password'
                  {...register('newPassword')}
                />
                <button
                  type="button"
                  onClick={toggleNewPasswordVisibility}
                  className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
                >
                  <img src={viewIcon} alt="view icon" />
                </button>
              </div>
              <div className='mb-4 relative'>
                <label className='text-[#262626] text-sm font-semibold font-inter'>
                  Confirm Password
                </label>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                  placeholder='Confirm Password'
                  {...register('confirmPassword')} // Register input
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
                >
                  <img src={viewIcon} alt="view icon" />
                </button>
              </div>
            </div>
            <button
              type='submit'
              className='bg-[#0065A2] px-5 mt-3 float-end py-3 rounded-lg text-base font-semibold font-inter text-white '
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default PersonalInformation
