import React from 'react';

const Modal = ({ question, icon, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] text-center ">
        <div className="flex justify-center mb-4">
          <img src={icon} alt="action-icon" className="w-20 h-w-20" />
        </div>
        <h3 className="text-[#262626] text-2xl font-inter font-normal mb-5">{question}</h3>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="px-14 py-2 bg-[#DA00041A] text-[#262626] text-base font-normal font-inter rounded-lg"
          >
            No
          </button>
          <button
            type='button' 
            onClick={() => {
              onConfirm();
              onClose(); 
            }}
            className="px-14 py-2 bg-[#DA0004] text-white text-base font-normal font-inter rounded-lg"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
