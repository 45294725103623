import React, { useEffect, useState } from 'react';
import DashboardBox from '../../../components/dashboardBox';
import TotalUsersIcon from '../../../components/media/images/totalusersIcon.svg';
import allusersicon from '../../../components/media/images/allusersicon.svg';
import DashboardUsersList from '../../../components/dashboardUsersList';
import DashboardDoctorsList from '../../../components/dashboardDoctorsList';
import doctorUnfiled from '../../../components/media/images/doctorUnfiled.svg';
import UGdoctorUnfiled from '../../../components/media/images/UGdoctorUnfiled.svg';
import doctorFiled from '../../../components/media/images/doctorFiled.svg';
import { getData } from '../../../components/Utility/apiServices';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getData('/admin/dashboard');
      setData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <div className='flex'>
        <h2 className='text-3xl text-[#262626] font-inter font-semibold mb-4'>
          Dashboard
        </h2>
        <p className='text-[#262626] ml-auto mt-2 text-base font-normal font-inter'>
          Total Users <span className='text-[#0065A2]'>{data?.total_users}</span>
        </p>
      </div>

      <div className='grid grid-cols-3 gap-4'>
        <DashboardBox
          icon={TotalUsersIcon}
          title={'Total Registered Users'}
          totalData={data?.patients_count}
        />

        <DashboardBox
          icon={doctorUnfiled}
          title={'Total Registered Service Providers'}
          totalData={data?.registered_doctors_count}
        />

        <DashboardBox
          icon={UGdoctorUnfiled}
          title={'Unregistered Service Providers'}
          totalData={data?.unregistered_doctors_count}
        />
      </div>

      <div className='grid grid-cols-2 gap-4 h-[55vh]'>
        <DashboardUsersList
          icon={allusersicon}
          title={'New Patients'}
          users={data?.new_patients} 
        />

        <DashboardDoctorsList
          icon={doctorFiled}
          title={'New Service Providers'}
          doctors={data?.new_doctors}
        />
      </div>
    </div>
  );
};

export default Dashboard;
