import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie'; 
import AdminLoginBg from '../../../components/media/images/AdminLoginBg.png';
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg';
import viewIcon from '../../../components/media/images/view.svg';
import { postInitialData } from '../../../components/Utility/apiServices';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await postInitialData('/admin/auth/login', { email, password });
      console.log('Login response:', response);
      
      Cookie.set('token', response.token, { expires: 20 });  
      Cookie.set('AdminToken', response.token, { expires: 20 });  
      Cookie.set('refresh_token', response.refresh_token, { expires: 20 });  
      Cookies.remove('DoctorToken')
      window.location.href = '/admin';  
    } catch (error) {
      setError(error.message); 
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = Cookies.get('AdminToken');
    const doctorToken = Cookies.get('DoctorToken');

    if (adminToken) {
      navigate('/admin/dashboard'); 
    }
    if (doctorToken) {
      navigate('/doctor/documentsOTP'); 
    }
  }, [navigate]);
  return (
    <div
      className='min-h-screen bg-cover bg-no-repeat bg-center flex items-center justify-center'
      style={{ backgroundImage: `url(${AdminLoginBg})` }}
    >
      <div className='p-5 bg-[#ffffffd1] rounded-3xl shadow-md w-full max-w-xl'>
        <img className='mx-auto' src={DashboardLogo} alt="logo" />
        <div className='my-3'>
          <h3 className='text-3xl text-[#262626] font-semibold font-inter'>Welcome Back!</h3>
          <p className='text-[#262626] text-sm font-light font-inter mt-1'>Sign in to your account</p>
        </div>
        <form onSubmit={handleLogin}>
          <div className='mb-4'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Email *</label>
            <input
              type='email'
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='mb-6 relative'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Password *</label>
            <input
              type={showPassword ? 'text' : 'password'}
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
            >
              <img src={viewIcon} alt="view icon" />
            </button>
          </div>
          {error && <p className='text-red-500 text-sm'>{error}</p>}
          <button
            type='submit'
            className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:bg-[#462ca3]'
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
