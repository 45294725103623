import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DoctorSideImg from '../../../components/media/images/DoctorSideImg.svg';
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg'
import viewIcon from '../../../components/media/images/view.svg'
import Select from 'react-select';

const CreateDoctorsPassword = () => {
  const navigate = useNavigate();

 
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  
  return (
    <div className="min-h-screen">
      <div className='grid grid-cols-2 gap-4'>
      <div className='px-14 py-10 w-full max-w-xl mx-auto'>
        <img className='mx-auto mb-5' src={DashboardLogo} alt="logo" />
        <div className='my-3'>
          <h3 className='text-3xl text-[#262626] font-semibold font-inter'>Create a New Password</h3>
          <p className='text-[#262626] text-sm font-light font-inter mt-1'>Enter a new password to update your password</p>
        </div>
        <form>
          {/* <div className='mb-4'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Name *</label>
            <input
              type='email'
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Name'
            />
          </div>
          <div className='mb-4'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Email *</label>
            <input
              type='email'
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Email'
           />
          </div>
          <div className='mb-4'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Phone *</label>
            <input
              type='text'
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Phone'
           />
          </div> */}
          {/* <div className='mb-4'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Speciality *</label>
            <input
              type='text'
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Email'
           />
          </div> */}
              {/* <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Speciality
              </label>
              <Select
                value={selectedSpeciality}
                onChange={setSelectedSpeciality}
                options={specialityOptions}
                className='w-full mt-1'
                placeholder='Select Speciality'
              />
            </div> */}
          <div className='mb-6 relative'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>New Password *</label>
            <input
              type={showPassword ? 'text' : 'password'}
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Enter Password'
            />
            <button
              type="button"
              onClick={togglePasswordVisibility1}
              className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
            >
              <img src={viewIcon} alt="view icon" />
            </button>
          </div>
          <div className='mb-6 relative'>
            <label className='text-[#262626] text-sm font-semibold font-inter'>Confirm Password *</label>
            <input
              type={showPassword2 ? 'text' : 'password'}
              className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
              placeholder='Confirm Password'
            />
            <button
              type="button"
              onClick={togglePasswordVisibility2}
              className='absolute right-3 top-12 transform -translate-y-1/2 focus:outline-none'
            >
              <img src={viewIcon} alt="view icon" />
            </button>
          </div>
          <Link to='/doctor/signIn'>
            <button
              //  type="submit"
              className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:shadow-lg'
            >
             Submit
            </button>
          </Link>
        </form>
          {/* <div className='text-center mt-5'>
            <span className='text-[#0065A2] text-lg font-inter font-medium'>Forget password?</span>
          </div> */}
 
      </div>

        <div className="h-[auto] overflow-hidden rounded-b-[30px]">
          <img
            src={DoctorSideImg}
            alt="sideImg"
            className="w-full rounded-b-[30px]"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateDoctorsPassword;
