import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { postBody } from './Utility/apiServices'

const ViewPDFData = ({ response, documentId, fetchReportData }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [showComments, setShowComments] = useState(false)
console.log(response)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  // Handle screen resize to check if it's mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleComments = () => {
    setShowComments(prevState => !prevState)
  }

  const onSubmit = async data => {
    const bodyData = {
      document_id: documentId,
      comment: data.comment
    }

    try {
      await postBody('/doctor/registered/report/comment', bodyData)
      Swal.fire({
        icon: 'success',
        title: 'Comment Posted',
        text: 'Your comment has been successfully posted.',
        confirmButtonText: 'OK'
      })
      // Fetch updated report data after successfully posting a comment
      await fetchReportData(documentId, response.otp)
      reset();
    } catch (error) {
      console.error('Error posting comment:', error.message)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while posting your comment.',
        confirmButtonText: 'OK'
      })
    }
  }

  if (!response) return "No Data Found!"

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-3'>
        <div className="user-data col-span-3 md:col-span-2 md:ml-20 flex p-5 gap-10">
          <div className='w-full md:w-11/12'>
            <div className='bg-[#EDEDED] rounded-xl border-t-4 border-t-[#3F3F3F] p-4'>
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2'>
                <div className='col-span-2 mb-4 lg:mb-0'>
                  <div className='flex gap-5'>
                    <img src={response?.profile?.avatar} alt="avatar" className='w-24 h-24 rounded-full border border-[#3F3F3F]' />
                    <div className='space-y-2'>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.name || "N/A"}</h5>
                      <div className='flex gap-1 items-center'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.3335 6.00001C3.3335 3.80012 3.3335 2.70018 4.01692 2.01676C4.70033 1.33334 5.80028 1.33334 8.00016 1.33334C10.2 1.33334 11.3 1.33334 11.9834 2.01676C12.6668 2.70018 12.6668 3.80012 12.6668 6.00001V10C12.6668 12.1999 12.6668 13.2998 11.9834 13.9833C11.3 14.6667 10.2 14.6667 8.00016 14.6667C5.80028 14.6667 4.70033 14.6667 4.01692 13.9833C3.3335 13.2998 3.3335 12.1999 3.3335 10V6.00001Z" stroke="#697585" strokeWidth="1.25" strokeLinecap="round" />
                          <path d="M7.3335 12.6667H8.66683" stroke="#697585" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6 1.33334L6.05933 1.68936C6.18792 2.46087 6.25221 2.84663 6.51679 3.08137C6.7928 3.32624 7.18407 3.33334 8 3.33334C8.81593 3.33334 9.2072 3.32624 9.4832 3.08137C9.7478 2.84663 9.81207 2.46087 9.94067 1.68936L10 1.33334" stroke="#697585" strokeWidth="1.25" strokeLinejoin="round" />
                        </svg>
                        <p className='text-[#697585] font-montserrat text-xs'>{response?.profile?.user?.contact_number || "N/A"}</p>
                      </div>
                      <div className='flex gap-1 items-center'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 3.33334C10 4.43791 8.82787 5.66668 8 5.66668C7.17213 5.66668 6 4.43791 6 3.33334C6 2.22878 6.8954 1.33334 8 1.33334C9.1046 1.33334 10 2.22878 10 3.33334Z" stroke="#697585" />
                          <path d="M10.6943 6C11.7096 6.8902 12.3676 8.39793 11.7768 9.80347C11.6494 10.1063 11.3574 10.3026 11.0342 10.3026C10.7056 10.3026 10.166 10.1973 10.0611 10.6249L9.32963 13.6082C9.17709 14.2303 8.62889 14.6667 8.00003 14.6667C7.37109 14.6667 6.82289 14.2303 6.67036 13.6082L5.93889 10.6249C5.83403 10.1973 5.29433 10.3026 4.96575 10.3026C4.6426 10.3026 4.35055 10.1063 4.22325 9.80347C3.63245 8.39793 4.29039 6.8902 5.30571 6" stroke="#697585" strokeLinecap="round" />
                        </svg>
                        <p className='text-[#697585] font-montserrat text-xs'>{response?.profile?.gender || "N/A"}</p>
                      </div><div className='flex gap-1 items-center'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 1.33334V2.66668M4 1.33334V2.66668" stroke="#697585" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.99716 8.66666H8.00316M7.99716 11.3333H8.00316M10.6608 8.66666H10.6668M5.3335 8.66666H5.33948M5.3335 11.3333H5.33948" stroke="#697585" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M2.3335 5.33334H13.6668" stroke="#697585" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.6665 8.16213C1.6665 5.25729 1.6665 3.80485 2.50125 2.90243C3.336 2 4.6795 2 7.3665 2H8.63317C11.3202 2 12.6637 2 13.4984 2.90243C14.3332 3.80485 14.3332 5.25729 14.3332 8.16213V8.50453C14.3332 11.4094 14.3332 12.8618 13.4984 13.7643C12.6637 14.6667 11.3202 14.6667 8.63317 14.6667H7.3665C4.6795 14.6667 3.336 14.6667 2.50125 13.7643C1.6665 12.8618 1.6665 11.4094 1.6665 8.50453V8.16213Z" stroke="#697585" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M2 5.33334H14" stroke="#697585" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className='text-[#697585] font-montserrat text-xs'>{response?.profile?.dob || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-between gap-2'>
                  <div><p className='text-[#697585] font-montserrat text-[10px]'>Diabetes Type</p>
                    <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.medicalInfo?.diabetes || "N/A"}</h5></div>
                  <div><p className='text-[#697585] font-montserrat text-[10px]'>Height</p>
                    <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.medicalInfo?.height || "N/A"}</h5></div>
                </div>
                <div className='flex flex-col justify-between gap-2'>
                  <div><p className='text-[#697585] font-montserrat text-[10px]'>Blood Group</p>
                    <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.medicalInfo?.bloodGroup || "N/A"}</h5></div>
                  <div><p className='text-[#697585] font-montserrat text-[10px]'>Allergy Type</p>
                    <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.medicalInfo?.allergies || "N/A"}</h5></div>
                </div>
                <div className='flex flex-col justify-between'>
                  <div><p className='text-[#697585] font-montserrat text-[10px]'>Body Weight</p>
                    <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.profile?.medicalInfo?.weight || "N/A"}</h5></div>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-4'>
                <div>
                  <p className='text-[#697585] font-montserrat text-[10px]'>Blood Pressure</p>
                  <div className='rounded-lg bg-[#FFFFFF] px-4 py-1.5 flex justify-between'>
                    <div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Upper Body</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestBloodPressure?.upperBody || "N/A"}</h5>
                    </div>
                    <div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Lower Body</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestBloodPressure?.lowerBody || "N/A"}</h5>
                    </div><div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Heart Rate</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestBloodPressure?.heartRate || "N/A"}</h5>
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-[#697585] font-montserrat text-[10px]'>Diabetes</p>
                  <div className='rounded-lg bg-[#FFFFFF] px-4 py-1.5 flex justify-between'>
                    <div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Glucose Level</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestDiabetes?.glucoseLevel || "N/A"}</h5>
                    </div>
                    <div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Test Type</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestDiabetes?.afterEating ? "After Eating" : "Before Eating"}</h5>
                    </div><div>
                      <p className='text-[#697585] font-montserrat text-[10px]'>Elapsed Time</p>
                      <h5 className='text-[#05162E] font-montserrat text-sm font-semibold'>{response?.latest_readings?.latestDiabetes?.hours ? response?.latest_readings?.latestDiabetes?.hours + "hrs" : "N/A"}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex my-4'>
              <h3 className='text-3xl font-montserrat font-bold'>Report Data</h3>
              <div className='ml-auto md:hidden block'>
                <Link
                  className='float-end ml-auto'
                  to={response?.report_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='text-[#0065A2] font-montserrat text-sm mt-3'>
                    View Report
                  </button>
                </Link>
                {/* <br></br>
                <button
                  onClick={toggleComments}
                  className='float-end ml-auto text-[#0065A2] font-montserrat text-sm mt-3 lg:hidden md:hidden block'
                >
                  {showComments ? 'Hide Comments' : 'Comments'}
                </button> */}
              </div>
            </div>
            <div>
              <p className='text-[#262626] font-montserrat text-sm font-semibold'>
                Patient Name
              </p>
              <p className='text-[#262626] font-montserrat text-sm font-normal'>
                {response?.uploaded_report_summary?.patient_name || "N/A"}
              </p>
            </div>

            <div className='grid grid-cols-4 gap-4 mt-3'>
              <div>
                <p className='text-[#262626] font-montserrat text-sm font-semibold'>
                  Patient Disease
                </p>
                <p className='text-[#262626] font-montserrat text-sm font-normal'>
                  {response?.uploaded_report_summary?.patient_disease || "N/A"}
                </p>
              </div>

              <div>
                <p className='text-[#262626] font-montserrat text-sm font-semibold'>
                  Disease Value
                </p>
                <p className='text-[#262626] font-montserrat text-sm font-normal'>
                  {response?.uploaded_report_summary?.disease_value || "N/A"}
                </p>
              </div>
            </div>
            <p className='text-[#262626] font-montserrat text-sm font-normal my-3'>
              {response?.uploaded_report_summary?.summary || "N/A"}
            </p>
          </div>
          <div className='hidden md:block w-1/12'>
            <img src='/images/pdf.png' className='' />
            <Link
              className=''
              to={response?.report_url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='text-[#0065A2] font-montserrat text-sm mt-3'>
                View Report
              </button>
            </Link>
          </div>
        </div>

        <div className='bg-[#F8F8F8] shadow-md m-5 p-3 col-span-1 relative '>
          <div className='h-[50vh] overflow-y-auto scrollbar-hide'>

            {response?.uploaded_report_comments?.map((commentData, index) => (
              <div key={index} className='mb-5'>
                <div className='flex items-start'>
                  <img
                    src={commentData?.avatar}
                    alt='avatar'
                    className='w-8 h-8 rounded-full mr-3'
                    />
                  <div className='flex-1'>
                    <h3 className='font-montserrat font-semibold text-base text-[#262626]'>
                      {commentData?.name}
                    </h3>
                    <p className='text-[#697585] font-montserrat font-normal text-xs ml-auto float-end'>
                      {commentData?.created_at}
                    </p>
                  </div>
                </div>
                <h3 className='font-montserrat font-normal text-sm text-[#262626] mb-2 break-words'>
                  {commentData?.comment}
                </h3>
                <hr />
              </div>
            ))}
</div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=' w-full mb-3 pb-10'>
                <textarea
                  rows={5}
                  className='w-full bg-white rounded-lg p-4 mt-3'
                  placeholder='Enter Comments'
                  {...register('comment', { required: 'Comment is required' })}
                />
                <button
                  type='submit'
                  className='bg-[#0065A2] px-10 mt-3 float-end py-1.5 rounded-lg text-base font-semibold font-inter text-white'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        {/* {isMobile ? (
          <>

            {showComments && (
              <div className={`absolute top-0 right-0 w-full h-full mt-[20px] bg-white p-5 shadow-lg z-50 transition-transform duration-1000 ease-in-out ${showComments ? 'translate-x-0' : 'translate-x-full'
                }`}>
                <button
                  onClick={toggleComments}
                  className='absolute top-[-20px] right-3 text-gray-500 text-[25px]'
                >
                  X
                </button>
                <div className='overflow-y-auto h-[68vh]'>
                  {response?.uploaded_report_comments?.map((commentData, index) => (
                    <div key={index} className='mb-5'>
                      <div className='flex items-start'>
                        <img
                          src={commentData?.avatar}
                          alt='avatar'
                          className='w-8 h-8 rounded-full mr-3'
                        />
                        <div className='flex-1'>
                          <h3 className='font-montserrat font-semibold text-base text-[#262626]'>
                            {commentData?.name}
                          </h3>
                          <p className='text-[#697585] font-montserrat font-normal text-xs ml-auto float-end'>
                            {commentData?.created_at}
                          </p>
                        </div>
                      </div>
                      <h3 className='font-montserrat font-normal text-sm text-[#262626] mb-2'>
                        {commentData?.comment}
                      </h3>
                      <hr />
                    </div>
                  ))}

                  <form onSubmit={handleSubmit(onSubmit)} className='absolute bottom-0 w-full'>
                    <div className=' w-full  pb-5 bg-white '>
                      <textarea
                        rows={5}
                        className='w-full bg-white rounded-lg p-4 pr-7 mt-3 '
                        placeholder='Enter Comments'
                        {...register('comment', { required: 'Comment is required' })}
                      />
                      <button
                        type='submit'
                        className='bg-[#0065A2] px-10 mx-3 mt-3 py-1.5 rounded-lg text-base font-semibold font-inter text-white'
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        ) : (

        )} */}
      </div>
    </>
  )
}

export default ViewPDFData
