import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DoctorSideImg from '../../../components/media/images/DoctorSideImg.svg'
import DashboardLogo from '../../../components/media/images/DashboardLogo.svg'

const VerifyOtp = () => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState(['', '', '', '', ''])
  const inputRefs = useRef([])

  const handleOtpChange = (value, index) => {
    const updatedOtp = [...otp]
    updatedOtp[index] = value
    setOtp(updatedOtp)

    // Move focus to the next input field
    if (value && index < 4) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (e, index) => {
    // If the user presses backspace and the current input is empty, move to the previous input
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus()
    }
  }

  const handleSignup = e => {
    e.preventDefault()
    navigate('/documentsOTP')
  }

  return (
    <div className='min-h-screen'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='px-14 py-10 mx-auto w-full max-w-xl'>
          <img className='mx-auto mb-5' src={DashboardLogo} alt='logo' />
          <div className='my-3'>
            <h3 className='text-3xl text-[#262626] font-semibold font-inter'>
              OTP Verification
            </h3>
            <p className='text-[#262626] text-sm font-light font-inter mt-1'>
              Enter OTP Sent To... (Jhondeo@email.com)
            </p>
          </div>
          <form onSubmit={handleSignup}>
            <div className='flex justify-between mb-3'>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type='text'
                  maxLength='1'
                  value={digit}
                  onChange={e => handleOtpChange(e.target.value, index)}
                  onKeyDown={e => handleKeyDown(e, index)}
                  ref={el => (inputRefs.current[index] = el)}
                  className='w-20 h-20 text-center text-xl border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                />
              ))}
            </div>
            <div className='my-1'>
              <p className='text-[#262626] text-lg font-medium font-inter'>
                Don't Receive The OTP? &nbsp;
                <Link to={'#'}>
                  <span className='text-[#0065A2] text-lg font-inter font-medium'>
                    Resend OTP Code
                  </span>
                </Link>
              </p>
            </div>
            <Link to={'/doctor/createPassword'}>
            <button
              type='submit'
              className='w-full bg-[#0065A2] text-white text-base font-inter font-semibold py-2 rounded-md hover:shadow-lg mt-3'
            >
              Next
            </button>
            </Link>
          </form>
        </div>

        <div className='h-[100vh] overflow-hidden rounded-b-[30px]'>
          <img
            src={DoctorSideImg}
            alt='sideImg'
            className='w-full rounded-b-[30px]'
          />
        </div>
      </div>
    </div>
  )
}

export default VerifyOtp
