import React, { useState, useEffect } from 'react';
import Table from '../../../components/table';
import DeleteIcon from '../../../components/media/images/DeleteIcon.svg';
import searchIcon from '../../../components/media/images/searchIcon.svg';
import Modal from '../../../components/modal';
import userBlockIcon from '../../../components/media/images/userBlockIcon.svg';
import { getData, deleteData } from '../../../components/Utility/apiServices'; 

const UnregisteredDoctorsList = () => {
  const [filterText, setFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalQuestion, setModalQuestion] = useState('');
  const [modalIcon, setModalIcon] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [doctorIdToDelete, setDoctorIdToDelete] = useState(null); 

  const fetchData = async () => {
    try {
      const response = await getData('/admin/unregisteredDoctors');
      
        setData(response);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = data?.filter(item =>
    item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: 'Name',
      selector: row => (
        <div className='flex items-center'>
          {row.avatar ? (
            <img
              src={row.avatar}
              alt={row.email}
              className='w-8 h-8 rounded-full mr-2'
            />
          ) : (
            <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center text-white mr-2'>
              {row.name[0].toUpperCase()}
            </div>
          )}
          <span>{row.name}</span> 
        </div>
      ),
      sortable: true
    },
    // {
    //   name: 'Email',
    //   selector: row => row.email,
    //   sortable: true
    // },
    {
      name: 'Phone',
      selector: row => row.contact_number,
      sortable: true
    },
    {
      name: 'Date',
      selector: row => new Date(row.created_at).toLocaleDateString(),
      sortable: true
    },
    {
      name: 'Action',
      cell: row => (
        <div className='flex'>
          <button
            onClick={() => {
              setDoctorIdToDelete(row.id); 
              openModal(`Are you sure you want to delete this doctor?`, DeleteIcon);
            }}
            className='mx-1'
          >
            <img src={DeleteIcon} alt='deleteButton' />
          </button>
          <button
            onClick={() =>
              openModal(`Are you sure you want to block this doctor?`, userBlockIcon)
            }
            className='mx-1'
          >
            <img src={userBlockIcon} alt='BlockButton' />
          </button>
        </div>
      )
    }
  ];

  const handleDelete = async () => {
    try {
      if (doctorIdToDelete) {
        const response = await deleteData(`/admin/unregisteredDoctors/${doctorIdToDelete}`);
        if (response.status === 200) {
          await fetchData(); 
          setIsModalOpen(false);
        } else {
          console.error('Failed to delete data:', response.message);
          alert('Failed to delete doctor.');
        }
      }
    } catch (error) {
      console.error('Error deleting doctor:', error);
      alert('Failed to delete doctor.');
    }
  };

  const openModal = (question, icon) => {
    setModalQuestion(question);
    setModalIcon(icon);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className='flex'>
        <h2 className='text-3xl text-[#262626] font-inter font-semibold mb-4'>
        Unregistered Doctors
        </h2>
        <p className='text-[#262626] ml-auto mt-2 text-base font-normal font-inter'>
          Total Unregistered Doctors <span className='text-[#0065A2]'>{data?.length}</span>
        </p>
      </div>
      <div
        style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
        className='border-[1px] mt-5 border-[#E8E8E8] rounded-xl bg-white pt-4 px-3 '
      >
        <div className='relative '>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <img src={searchIcon} alt={'searchIcon'} />
          </span>
          <input
            type='text'
            placeholder='Search by Name'
            className='pl-10 p-2 border border-gray-300 rounded'
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Table columns={columns} data={filteredData} />
        )}
      </div>
      {isModalOpen && (
        <Modal
          question={modalQuestion}
          icon={modalIcon}
          onClose={closeModal}
          onConfirm={handleDelete} 
        />
      )}
    </div>
  );
};

export default UnregisteredDoctorsList;
