import React from 'react'
import userImg from './media/images/userImg.svg'
import { Link } from 'react-router-dom'

function DashboardUsersList ({ icon, title, users }) {
  const isScrollable = users.length > 4;

  return (
    <div
      style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
      className='border-[1px] mt-5 border-[#E8E8E8] rounded-xl bg-white pt-4 px-0  overflow-hidden h-full'
    >
      <div className='flex px-4'>
        <div>
          <img src={icon} alt='icon' />
        </div>
        <div className='mt-2 ml-2'>
          <h3 className='font-inter text-lg font-semibold text-[#101828]'>
            {title}
          </h3>
        </div>
        <span className='text-[#0086C9] text-base font-inter font-semibold ml-auto mt-2.5'>
          <Link to={'/admin/userlist'}>View all</Link>
        </span>
      </div>
      <div className='bg-[#F9FAFB] py-3 px-5'>
        <span className='text-[#262626] text-sm font-medium font-inter'>
          Name
        </span>
        <span className='text-[#262626] text-sm font-medium font-inter float-end mr-3'>
          Registered Date
        </span>
      </div>
      <div
        className={`${
          isScrollable ? 'h-full overflow-y-scroll' : 'h-full'
        }`}
      >
        {users.map(user => (
          <div
            key={user.id}
            className='flex px-5 py-3 border-b-[1px] border-b-[#EAECF0] '
          >
            <div className='mt-2 w-10 h-10'>
              <img
                src={user.avatar || userImg}
                className=' rounded-full'
                alt='User Avatar'
              />
            </div>
            <div className='mt-4 ml-2'>
              <h3 className='font-inter text-base font-medium text-[#262626]'>
                {user.name}
              </h3>
            </div>
            <div className='ml-auto '>
              <span className='text-[#262626] text-sm font-inter font-normal'>
                {new Date(user.created_at).toLocaleDateString()}
              </span>
              <br />
              <span className='text-[#475467] text-sm font-inter font-normal'>
                {new Date(user.created_at).toLocaleTimeString()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DashboardUsersList
