import React, { useState } from 'react';
import Select from 'react-select';
import avatar from '../../../components/media/images/Avatar.svg';
import { Link } from 'react-router-dom';
import UDMainTopBar from '../../../components/UDmainTopBar';

const UnregisteredPersonalInformation = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const specialityOptions = [
    { value: 'cardiology', label: 'Cardiology' },
    { value: 'neurology', label: 'Neurology' },
    { value: 'pediatrics', label: 'Pediatrics' },
    { value: 'general-surgery', label: 'General Surgery' },
    // Add more options as needed
  ];

  return (
    <>
      <UDMainTopBar />
      <div className='mt-5 bg-cover bg-no-repeat bg-center flex items-center justify-center'>
        <div className='lg:px-52 md:px-8 px-8 py-1 bg-[#ffffffd1] rounded-3xl w-full max-w-screen-2xl'>
          <h3 className='text-[#0065A2] text-[40px] font-semibold font-inter'>Personal Information</h3>
          <hr className='py-2'></hr>
          <img
            className='mx-auto rounded-full w-32 h-32 object-cover'
            src={selectedImage || avatar}
            alt='avatar'
          />
          <div className='mx-auto text-center mt-2'>
            <label
              htmlFor='imageUpload'
              className='text-[#0065A2] text-lg font-medium font-montserrat cursor-pointer'
            >
              Upload Image
            </label>
            <input
              id='imageUpload'
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleImageChange}
            />
          </div>
          <div className='grid  sm:grid-cols-12 md:grid-cols-6 lg:grid-cols-4 gap-3'>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Name
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Name'
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Email
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Email'
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Phone
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Phone'
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Speciality
              </label>
              <Select
                value={selectedSpeciality}
                onChange={setSelectedSpeciality}
                options={specialityOptions}
                className='w-full mt-1'
                placeholder='Select Speciality'
              />
            </div>
          </div>
          <button className='bg-[#0065A2] px-5 mt-3 float-end py-3 rounded-lg text-base font-semibold font-inter text-white'>
            Save Changes
          </button>
        </div>
      </div>



      <div className='mt-10 bg-cover bg-no-repeat bg-center flex items-center justify-center'>
        <div className='lg:px-52 md:px-8 px-8 py-1 bg-[#ffffffd1] rounded-3xl w-full max-w-screen-2xl'>
      <h3 className='text-[#0065A2] text-[40px] font-semibold font-inter'>Password Change</h3>
      <hr className='py-2'></hr>
          {/* <img
            className='mx-auto rounded-full w-32 h-32 object-cover'
            src={selectedImage || avatar}
            alt='avatar'
          /> */}
          {/* <div className='mx-auto text-center mt-2'>
            <label
              htmlFor='imageUpload'
              className='text-[#0065A2] text-lg font-medium font-montserrat cursor-pointer'
            >
              Upload Image
            </label>
            <input
              id='imageUpload'
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleImageChange}
            />
          </div> */}
          <div className='grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3'>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Current Password
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter Current Password'
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                New Password
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Enter New Password'
              />
            </div>
            <div className='mb-4'>
              <label className='text-[#262626] text-sm font-semibold font-inter'>
                Confirm Password
              </label>
              <input
                type='text'
                className='w-full adminFields mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600'
                placeholder='Confirm Password'
              />
            </div>
 
          </div>
          <button className='bg-[#0065A2] px-5 mt-3 float-end py-3 rounded-lg text-base font-semibold font-inter text-white '>
            Save Changes
          </button>
        </div>

      </div>
    </>
  );
};

export default UnregisteredPersonalInformation;
